



















































































































import { Component, Ref, Vue } from "vue-property-decorator";
import AttachmentsView from "@/components/AttachmentsView/index.vue";
import ExportWord from "@/components/DownloadWord/index.vue";
import AuditBlock, {AuditApi} from "@/components/Audit/AuditBlock.vue";

import {
  ExternalMonthlySummarizeDto,
  OrganizationUnitDto,
  ExternalProjectDetailDto,
  AttachmentHostType,
  AuditFlowScope,
  ProjectSpecificGoalDto,
  ExternalProjectSpecificPlanDto,
} from "@/api/appService";
import api from "@/api";
import moment from "moment";
import FileList from "@/components/CustomForm/TableDetail/components/fileList.vue";
import ProvinceCityAreaContent from "@/components/CustomForm/TableDetail/components/ProvinceCityAreaContent.vue";
@Component({
  name: "ExternalMonthlySummarizeDetail",
  components: {
    ProvinceCityAreaContent,
    FileList,
    AttachmentsView,
    ExportWord,
    AuditBlock,
  },
  filters: {
    formatStatus(value: string, list: any[]) {
      var label = "";
      if (list && list.length > 0) {
        list.map((item: any) => {
          if (item.value === value) {
            label = item.label;
          }
        });
      }
      return label;
    },
    getSpecificGoal(goalId: string, list: ProjectSpecificGoalDto[]) {
      if (goalId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ProjectSpecificGoalDto) => {
            if (effectItem.id == goalId) {
              effect = effectItem.effect;
              return;
            }
          });
        }
        return effect;
      }
    },

    getSpecificPlan(planId: string, list: ExternalProjectSpecificPlanDto[]) {
      if (planId == "") {
        return "其他";
      } else {
        let effect: string | undefined = "";
        if (list && list.length > 0) {
          list.map((effectItem: ExternalProjectSpecificPlanDto) => {
            if (effectItem.id == planId) {
              effect = effectItem.title;
              return;
            }
          });
        }
        return effect;
      }
    },
  },
})
export default class ExternalMonthlySummarizeDetail extends Vue {

  detailId?: number;
  detail: ExternalMonthlySummarizeDto = {
    project: {},
  };
  unitList?: OrganizationUnitDto[] = [];
  selector = "table-detail";
  filename = "项目月总结表";
  styles = "";
  externalProject: ExternalProjectDetailDto = {};
  statusList = [
    {
      value: "None",
      label: "未展开",
    },
    {
      value: "Adjust",
      label: "调整",
    },
    {
      value: "Complete",
      label: "已完成",
    },
  ];
  get hostTypeFile() {
    return AttachmentHostType.External_Project_Monthly_Summarize_Photo;
  }

  get hostType() {
    return AttachmentHostType.External_Project_Monthly_Summarize_Attachment;
  }

  created() {
    if (this.$route.params.id) {
      this.detailId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }
  getValue(
    provinceId: string | number,
    cityId: string | number,
    areaId: string | number,
    townId: string | number
  ) {
    let valueData = {
      provinceId: provinceId,
      cityId: cityId,
      areaId: areaId,
      townId: townId,
    };
    return valueData;
  }


  get auditApi(){
    return {canAudit: api.externalMonthlySummarize.canAudit,userAudit: api.externalMonthlySummarize.audit} as AuditApi
  }

  // 获取详情
  async fetchDetail() {
    await api.externalMonthlySummarize
      .get({ id: this.detailId })
      .then((res) => {
        this.getProjectDetail(res.project!.id);
        this.detail = { ...res };
      });
  }


  getProjectDetail(projectId: any) {
    api.externalProject
      .getDetailByProjectId({ projectId: projectId })
      .then((res) => {
        this.externalProject = res;
        // this.goalList = res.effects!;
        // var other = this.goalList.push({ id: "", effect: "其他" });
        // //
        // this.specificPlanList = res.specificPlans!;
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
