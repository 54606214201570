

























































































































import api from "@/api/index"; //ABP API接口
import { Vue, Component } from "vue-property-decorator";

import {
  ExternalMonthlySummarizeDto,
  ExternalMonthlySummarizeDtoPagedResultDto,
  PayType,
  AuditFlowScope,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "ExternalMonthlySummarizeList",
  components: {
    // EditLink,
    PagedTableView,
    AgileAuditTag,
    // SummarizeDetail
  },
})
export default class ExternalMonthlySummarizeList extends Vue {
  queryForm: any = {
    displayName: "",
    date: "",
  };

  editLinkId = 0;
  private currentDetail: ExternalMonthlySummarizeDto = {};

  created() {
    if (this.$route.query.date) {
      this.queryForm.date = this.$route.query.date;
    }
  }

  // 获取表数据
  fetchData(params: any) {
    console.log("test:" + params);
    return api.externalMonthlySummarize.getAll(params);
  }

  handleMenu($row: any) {
    switch ($row.type) {
      case "detail":
        this.handleDetail($row.item);
        break;

      case "edit":
        this.handleEdit($row.index, $row.item);
        break;

      case "delete":
        this.handleDelete($row.index, $row.item);
        break;

      case "startAudit":
        this.handleStartAudit($row.index, $row.item);
        break;

      case "auditFlow":
        this.handleAuditFlow($row.index, $row.item);
        break;
    }
  }

  handleCreate() {
    this.$router.push({
      name: "externalMonthlySummarizeCreate",
    });
  }

  handleEdit(index: number, row: any) {
    this.$router.push({
      name: "externalMonthlySummarizeEdit",
      params: {
        id: row.id,
      },
    });
  }

  //  提交审核
  handleStartAudit(index: number, row: ExternalMonthlySummarizeDto) {
    // api.externalMonthlySummarize
    //   .startAudit({ body: { id: row.id } })
    //   .then(res => {
    //     this.$message.success("已提交");
    //   });
  }

  handleViewDetail(row: ExternalMonthlySummarizeDto) {
    this.currentDetail = row;
    (this.$refs.summarizeDetail as any).show = true;
  }

  handleDetail(row: any) {
    this.$router.push({
      name: "externalMonthlySummarizeDetail",
      params: {
        id: row.id,
      },
    });
  }

  // 删除
  async handleDelete(index: number, row: ExternalMonthlySummarizeDto) {
    this.$confirm("你确定删除吗?", "提示").then(async () => {
      await api.externalMonthlySummarize
        .delete({
          id: row.id,
        })
        .then((res) => {
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        });
    });
  }

  handleAuditFlow(index: number, row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.ExternalMonthlySummarize,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }

  handelOnSaved() {
    this.fetchData(this.queryForm);
  }
}
